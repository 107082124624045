@use "../abstracts/variables";

.controls {
  overflow-y: hidden;

  .label {
    color: variables.$primaryColor;
    font-size: 13px;
    line-height: 40px;
    width: 120px;
  }

  .control {
    line-height: 40px;
    position: relative;

    &>div {
      display: inline-block;
      margin-right: 30px;
      color: #888;
      size: 13px;
    }

    .error {
      border: 2px solid #f00;
    }

    &::after {
      position: absolute;
      right: 10px;
      font-size: 12px;
      color: #ccc;
      line-height: 45px;
    }

    &.unit-mm::after {
      content: "mm";
    }

    &.unit-mms::after {
      content: "mm/s";
    }

    &.unit-degree::after {
      content: "°C";
    }

    &.unit-angle::after {
      content: "°";
    }

    &.unit-percent::after {
      content: "%";
    }
  }

  .radio {
    cursor: pointer;

    >div {
      width: 15px;
      height: 15px;
      background-image: url("#{variables.$s3}/images/icon-radio-off.png");
      background-size: 15px 15px;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }

    .selected {
      background-image: url("#{variables.$s3}/images/icon-radio-on.png");
    }

    .grey-out {
      cursor: auto;
    }

    .grey-out+span {
      color: #e0e0e0;
    }
  }

  .checkbox {
    cursor: pointer;

    >div {
      width: 15px;
      height: 15px;
      background-image: url("#{variables.$s3}/images/icon-checkbox-off.png");
      background-size: 15px 15px;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }

    .selected {
      background-image: url("#{variables.$s3}/images/icon-checkbox-on.png");
    }

    .grey-out {
      cursor: auto;
    }

    .grey-out+span {
      color: #e0e0e0;
    }
  }

  .switch-container {
    margin-left: 30px;
    position: relative;

    div {
      display: inline-block;
    }

    .onoffswitch {
      position: absolute;
      top: 9px;
      left: 42px;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 5px;
    left: 130px;
    line-height: 20px;
    margin-right: 0;

    select {
      width: 270px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("#{variables.$s3}/images/arrow-down.png") no-repeat;
      background-position: 245px 9px;
      background-size: 16px 10px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e0e0df;
    }
  }

  .textarea-container {
    margin-right: 0;
    width: 100%;
    height: 335px;

    textarea {
      padding: 5px;
      width: 100%;
      height: 100%;
      line-height: 20px;
      border: 1px solid #ddd;
      border-radius: 4px;
      resize: none;
      overflow-y: scroll;
    }
  }

  input {
    width: 90px;
    height: 30px;
    font-size: 15px;
    color: #888;
  }

  input.url {
    width: 400px;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"] {
    padding-left: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    // box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    // background: linear-gradient(#FFF,#FEFEFE);
  }

  select {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background-image: url("#{variables.$s3}/images/icon-3d-arrow-down.png");
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-color: transparent;
    background-position: 150px 8px;
    width: 170px;
    height: 25px;
    letter-spacing: 0.3px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    padding-left: 10px;

    &.no-triangle {
      background-image: none;
    }
  }

  .slider-container {
    margin-right: 20px;
    position: relative;

    .slider {
      width: 150px;
      position: absolute;
      right: 0;
      top: -20px;
    }
  }

  // for slider
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    box-shadow: 3.7px 3.7px 0 #ffffff, 0 0 3.7px #fff;
    background-color: #e0e0df;
    border-radius: 0;
    border: 0 solid rgba(0, 0, 0, 0);
  }

  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(130, 13, 13, 0);
    border: 2.5px solid #aaa;
    height: 13px;
    width: 13px;
    border-radius: 12px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #e0e0df;
  }
}

.ant-select .ant-select-selector {
  border: 1px solid #d9d9d9;
  transition: border 0.3s;
}
