.modal-diode-calibration {
  .img-center {
    background-size: cover !important;
    background-position: 50% 50% !important;
    width: 50%;
    height: 200px;
    margin-bottom: 20px;
    float: left;
    position: relative;

    .virtual-square {
      position: absolute;
      border: 1px dashed #ff3300;
    }
  }

  .hint-icon {
    position: absolute;
    top: -36px;
    right: 0px;
    padding-right: 19px;
    width: 40px;
    height: 17px;
    line-height: 17px;
    color: #626262;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }

  .camera-control {
    position: absolute;
    border-style: solid;
    width: 0px;
    height: 0px;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }

    &.up {
      border-color: transparent transparent #333333 transparent;
      border-width: 0px 10px 16px 10px;
      top: 5px;
      left: 90px;
    }

    &.down {
      border-color: #333333 transparent transparent transparent;
      border-width: 16px 10px 0px 10px;
      bottom: 5px;
      left: 90px;
    }

    &.left {
      border-color: transparent #333333 transparent transparent;
      border-width: 10px 16px 10px 0px;
      top: 90px;
      left: 5px;
    }

    &.right {
      border-color: transparent transparent transparent #333333;
      border-width: 10px 0px 10px 16px;
      top: 90px;
      right: 5px;
    }
  }

  .hint-modal-background {
    position: fixed;
    z-index: 1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);

    .hint-box {
      position: absolute;

      .arrowup {
        margin-left: 3px;
        width: 0px;
        height: 0px;
        border-width: 0px 5px 7px 5px;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
      }

      .arrowdown {
        margin-left: 3px;
        width: 0px;
        height: 0px;
        border-width: 7px 5px 0px 5px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent;
      }

      .hint-body {
        height: 40px;
        background: #fff;
        padding: 10px;
        color: #626262;
        border-radius: 4px;
        font-size: 13px;
      }
    }
  }

  .controls {
    float: left;
    margin-left: 20px;

    label {
      font-weight: bold;
      display: inline-block;
      color: #555;
      width: 70px;
    }

    .ui-control-unit-input-v2 {
      .unit {
        margin-left: -30px;
        margin-right: 30px;
        font-size: 10px;
      }
    }

    .control>div {
      margin-right: 0;
    }
  }

  .modal-alert {
    width: 440px;
    /* min-height: 320px; */
    padding: 22px 0 0 0;
  }

  .caption,
  .message {
    overflow: visible;
    margin: 0 0 20px 0;
    padding: 0 19px;
  }

  .caption {
    color: #626262;
    font-size: 15px;
    line-height: 17px;
    text-align: left;
  }

  .message {
    color: #989898;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .button-group {
    border: none;
    border-top: 1px solid #e0e0df;
    display: block;
    height: 73px;
    padding: 19px;
    position: relative;

    .btn {
      margin: 0;
      padding: 0 10px;

      &.disabled {
        opacity: 0.5;
      }

      &.btn-alone-right-1 {
        position: absolute;
        width: 80px;
        right: 19px;
      }

      &.btn-alone-right-2 {
        position: absolute;
        width: 80px;
        right: 110px;
      }

      &.pull-right {
        margin-left: 20px;
      }
    }

    .btn-alone-right,
    .btn-alone-right-1 {
      background: #555 !important;
      border: 0;
      color: white;

      &.disabled {
        opacity: 0.5;
      }

      &:hover:not(.disabled) {
        opacity: 0.8;
      }
    }
  }
}
