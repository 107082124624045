.about-beam-studio {
  padding: 20px 60px 20px 60px;
  background: #f8f8f8;
  text-align: center;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);

  img {
    width: 60px;
  }

  .app-name {
    font-weight: bold;
    margin: 10px;
  }

  .version {
    font-size: 10px;
    margin: 10px;
  }

  .copyright {
    font-size: 10px;
    margin: 10px;
  }

  .credit {
    font-size: 10px;
    margin: 10px;
    max-width: 190px;
    a {
      color: #1199cc;
      font-size: 10px;
    }
  }

  .btn {
    height: 28px;
    line-height: 28px;
    background: #f8f8f8;
  }
}
