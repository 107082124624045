@use "../abstracts/variables";

.flux-monitor {
  $panelWidth: 31rem;
  $white: #fff;
  $light-grey: #ddd;
  left: 50%;

  &:focus {
    outline: none;
  }

  .main {
    background-color: white;
    border-radius: 4x;
    height: 40rem;
    width: $panelWidth;
    position: relative;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1);

    .title {
      height: 5rem;
      text-align: center;

      span {
        font-size: 1.6rem;
        color: #999;
        line-height: 5rem;
      }

      .back {
        font-size: 3rem;
        position: absolute;
        top: 0px;
        left: 15px;
        color: variables.$primaryColor;
        cursor: pointer;

        img {
          width: 28px;
          position: relative;
          top: 7px;
          left: 4px;
        }
      }

      .back:hover {
        color: #333;
      }

      .close {
        width: 26px;
        height: 26px;
        right: 18px;
        top: 10px;
      }
    }

    .body {
      height: 70%;

      .device-content {
        position: relative;
        padding: 0 20px;
        overflow-y: auto;
        height: 100%;
        border-radius: 4px;

        &::-webkit-scrollbar {
          display: none;
        }

        .camera {
          height: 100%;
        }

        img {
          width: 100%;
        }

        .spinner-wrapper {
          position: absolute;
          width: calc(100% - 20px);
          margin: auto;
          top: 200px;
        }

        .camera-image {
          width: 100%;
          height: 100%;
          object-fit: cover;

          &.hd {
            transform: rotate(270deg);
          }

          &.beambox-camera {
            transform: rotate(0);
            object-fit: contain;
          }
        }

        .camera-relocate-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          width: calc(100% - 40px);

          .img-container {
            width: 100%;
            max-height: 145px;
            overflow-y: hidden;
          }

          .camera-image {
            width: 100%;
            min-height: 145px;
          }

          .bars {
            width: 1px;
            height: 15px;
            display: block;
            position: absolute;
            background-color: white;

            &.shadow {
              box-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
          }

          .bar2 {
            transform: translateX(7px) translateY(-7px) rotate(90deg);
          }

          .origin-mark-wrapper {
            position: absolute;
            width: 15px;
            height: 15px;
          }

          .relocate-origin-mark-wrapper {
            position: absolute;
            width: 15px;
            height: 15px;

            .bars {
              background-color: greenyellow;
            }

            .relocate-origin {
              position: absolute;
              top: 20px;
              width: 60px;
              color: greenyellow;
              font-size: 13px;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
          }

          .maintain-move-panel {
            width: 180px;
            height: 180px;
            margin: 10px 0 0 0;
          }
        }

        .status-info {
          position: absolute;
          width: 332px;
          height: 100px;
          bottom: 0px;
          padding: 20px 10px 10px 10px;
          text-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

          .verticle-align {
            float: left;
            position: relative;
            bottom: -8px;
            color: #fff;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

            div {
              margin: 5px 0;
            }
          }

          .btn-relocate-container {
            float: right;
            height: 100%;
            display: flex;
            align-items: center;

            .btn-relocate {
              cursor: pointer;
              display: flex;
              height: 25px;
              background-color: #333;
              padding: 5px;
              border-radius: 5px;
              opacity: 0.7;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

              img {
                width: 15px;
                height: 15px;
              }

              .relocate-origin {
                padding-left: 5px;
                font-size: 13px;
                line-height: 15px;
                color: white;
              }

              &:hover {
                opacity: 1;
              }
            }
          }

          .status-info-progress {
            float: right;
            bottom: -8px;
            position: relative;
            font-size: 1.2em;
            color: #fff;
          }
        }

        .status-info.running {
          background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
          border-radius: 2px;
        }

        .btn-snap {
          position: absolute;
          top: 10px;
          right: 28px;
          opacity: 0.5;
          color: white;
          cursor: pointer;
          z-index: 10;

          &:hover {
            opacity: 1;
          }
        }
      }

      .close {
        right: 20px;
      }
    }

    .operation {
      margin-top: 15px;

      .controls {
        position: absolute;
        color: variables.$primaryColor;
        text-align: center;
        width: 60px;
        cursor: pointer;

        &::-webkit-scrollbar {
          display: none;
        }

        .description {
          padding-top: 5px;
          font-size: 1rem;

          &.on {
            color: #333;
          }
        }
      }

      .controls:hover {
        color: #333;
        cursor: pointer;
        filter: brightness(80%);
        -webkit-filter: brightness(80%);

        >div {
          opacity: 1;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      .left {
        left: 2rem;
      }

      .center {
        margin: auto;
        left: 0;
        right: 0;
      }

      .right {
        right: 2rem;
      }

      .btn-control {
        cursor: pointer;
        background-size: 50px;
        background-position: 3px 0px;
        height: 33px;
        opacity: 0.4;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .btn-relocate {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          filter: brightness(0.3);
        }
      }

      .btn-cancel {
        background-image: url("#{variables.$s3}/images/btn-run.svg");
        transform: scaleX(-1);
      }

      .btn-go {
        background-image: url("#{variables.$s3}/images/btn-run.svg");
      }

      .btn-stop {
        background-image: url("#{variables.$s3}/images/btn-stop.svg");
      }

      .btn-pause {
        background-image: url("#{variables.$s3}/images/btn-pause.svg");
      }

      .btn-download {
        background-image: url("#{variables.$s3}/images/btn-download.svg");
      }

      .btn-upload {
        background-image: url("#{variables.$s3}/images/btn-upload.svg");
      }

      .btn-camera {

        background-image: url("#{variables.$s3}/images/btn-camera.svg");
        &.on {
          opacity: 1;

          &~div {
            opacity: 1;
          }
        }
      }

      input {
        bottom: 0;
        display: block;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: -20px;
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 200px;
      }
    }

    .wait {
      text-align: center;
      margin-top: 30px;
      font-size: 1.2rem;
      color: #b3b3b3;
    }
  }

  .sub {
    background-color: white;
    border-radius: 2px;
    height: 6rem;
    width: $panelWidth;
    position: relative;
    margin-top: 5px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1);

    .wrapper {
      padding: 1.5rem 1.5rem 0 1.5rem;

      .row {
        font-size: 1.2rem;
        color: #888;
        width: 100%;
        height: 20px;

        div {
          display: inline-block;
        }

        .right {
          float: right;
          text-align: right;
        }

        &:last-child {
          margin-top: 3px;
        }
      }

      .temperature {
        float: left;
      }
    }

    .actions {
      position: absolute;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      bottom: 1rem;
      width: 100%;
    }

    .btn {
      font-size: 1rem;
      color: #fff;
      background-color: variables.$primaryColor;
      border-radius: 0;
      margin: 0 0.25rem;
      padding: 0 12px;
      transition: none;

      .filament {
        width: 12rem;
      }

      .file {
        width: 8rem;
      }

      .monitor {
        width: 8rem;
      }
    }

    .btn:hover {
      background-color: #333;
    }
  }

  ul {
    list-style: none;
    float: left;
    display: block;
    width: 100%;
  }

  li {
    display: inline;
    width: 25%;
    float: left;
  }
}

.file-monitor-v2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 400px;
  overflow: scroll;
  .folder, .file {
    color: variables.$primaryColor;
    width: 150px;
    height: 180px;
    text-align: center;

    &:hover {
      color: #333;
    }

    .image-wrapper {
      width: 100%;
      height: 135px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 10px 0;
      border-radius: 5px;

      img {
        width: 135px;
        border-radius: 3px;
      }
    }

    &.selected .image-wrapper {
      background: #EEE;
    }
  }
  .folder {
    .image-wrapper {
      img {
        padding: 20px;
      }
    }
  }
  .file {
    position: relative;
    .image-wrapper {
      img {
        border: 3px solid #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        padding: 0px;
      }
    }
    i {
      position: absolute;
      color: #ccc;
      top: 3px;
      right: -7px;
      visibility: hidden;
      z-index: 99;
      cursor: pointer;

      &.selected {
        visibility: visible;
      }

      &:hover {
        color: #333;
      }
    }
  }
  .name {
    font-size: 1rem;
    text-align: center;
    padding: 0 8px;
    overflow: hidden;
    border-radius: 5px;
    &.selected {
      color: white;
      background-color: #1890ff;
    }
  }
}
