.react-contextmenu-wrapper {
  display: inherit;
}
.react-contextmenu {
  display: none;
}
.react-contextmenu--visible {
  z-index: 1;
  display: unset;
  background-color: rgba($color: #ffffff, $alpha: 1);
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  padding: 5px 0px;
  &:focus {
    outline: none;
  }

  .react-contextmenu-item {
    padding: 0px 20px;
    line-height: 20px;
    height: 20px;
    font-size: 12px;
    &:hover:not(.react-contextmenu-item--disabled) {
      color: #ffffff;
      background-color: #2e5dea;
    }
    &.react-contextmenu-item--disabled {
      opacity: 0.3;
      &:focus {
        outline: none;
      }
    }
  }
  .seperator {
    height: 1px;
    background-color: #e3e3e3;
    margin: 5px 0;
  }
}
