.settings-studio {
  background-color: #f8f8f8;
  overflow: scroll;
  position: absolute;

  header {
    height: 47px;
    padding-top: 30px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .center {
    text-align: center;
  }

  .btn-long {
    width: 240px;
    height: 38px;
    line-height: 38px;
  }

  ul.nav {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    width: 70%;
    background-color: #fff;
    text-align: center;

    li.nav-item {
      // width: 165px; -- hiding flux cloud
      width: 50%;
      height: 47px;
      line-height: 47px;
      display: inline;
      float: left;
      border-bottom: 2px #e0e0e0 solid;
    }

    li.active {
      border-bottom: 2px #424242 solid;
    }

    a {
      font-size: 16px;
      letter-spacing: 0.3px;
      color: #777777;
      text-decoration: none;
    }
  }

  .tabs {
    .active {
      background: rgba(255, 255, 255, 0.7);
    }
  }

  .tab-container {
    margin-top: 40px;
    display: inline-block;
    width: 100%;
  }

  .tab-container.no-top-margin {
    margin-top: 0px;
  }

  .form {
    width: 70%;
    margin: 0 auto;

    .no-left-margin {
      margin-left: 0;
    }
  }

  .printer-list {
    ul {
      list-style: none;
      padding: 0;
    }
  }

  .general {
    padding-top: 85px;
    overflow-x: hidden;
    overflow-y: auto;

    input[type="text"],
    select {
      width: 240px;
      height: 32px;
      overflow: hidden;
      border: 1px solid #ccc;
      background-color: #ffffff;
      padding-left: 10px;
      border-radius: 2px;
    }

    .row-fluid {
      margin: 0 0 20px 0;
    }

    .subtitle {
      line-height: 40px;
      font-size: 20px;
      color: #333;
      border-bottom: 1px #ccc solid;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 400px;
    }

    .info-icon-medium {
      display: inline;
      vertical-align: middle;
      margin-left: 10px;

      img {
        width: 18px;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .info-icon-small {
      display: inline;
      vertical-align: middle;
      margin-left: 5px;

      img {
        width: 16px;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .ui.ui-control-unit-input-v2 {
      &.half {
        display: inline;

        input {
          width: 91px;
        }

        span.unit {
          margin-left: -35px;
          margin-right: 30px;
          font-size: 12px;
        }
      }
    }

    .controls {
      padding-bottom: 5px;

      .label {
        color: #555;
        width: 200px;
        font-size: 14px;
      }

      span {
        font-size: 14px;
      }

      label {
        color: #b3b3b3;
        width: 200px;
        font-size: 14px;
        padding-left: 5px;
      }
    }

    .path-input {
      input[type="text"] {
        width: 200px;
      }

      &.with-error {
        input[type="text"] {
          outline: 5px auto #e25601;
        }
      }
    }
  }

  footer {
    width: 100%;
    height: 100px;
    border-top: 1px #e0e0e0 solid;
    text-align: center;

    .actions {
      padding-top: 30px;

      .btn-cancel {
        background-color: #ffffff;
        border: 1px #e0e0e0 solid;
        font-size: 14px;
        color: #000;
      }

      .btn-cancel:hover {
        border: 1px #4a4a4a solid;
      }

      .btn-done {
        min-width: 5em;
        background-color: #555;
        font-size: 14px;
        color: #fff;
      }

      .btn-done:hover {
        background-color: #000;
      }
    }
  }

  .span8 {
    display: inline-flex;
  }

  .font1 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #424242;
  }

  .font2 {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: #555;
  }

  .font3 {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #424242;
  }

  .font4 {
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #555;
  }

  .font5 {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #424242;
  }

  .btn {
    &.disabled {
      pointer-events: none;
    }
  }

  .btn-done {
    background: #424242;
    border-radius: 4px;
    line-height: 36px;
    font-size: 14px;
    color: white;
    padding: 0 20px;
    margin: 20px 20px 20px 0px;
    display: inline-block;

    &:hover {
      background: #222;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    }
  }

  .btn-cancel {
    border: 1px solid rgba(29, 28, 29, 0.3);
    border-radius: 4px;
    line-height: 34px;
    font-size: 14px;
    color: #333333;
    padding: 0 20px;
    margin: 20px 0px;
    display: inline-block;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    }
  }
}

/*for cloud*/

.settings .cloud {
  text-align: center;
}

.settings .cloud .description {
  padding-top: 20px;
}

.settings .cloud .description div {
  padding-bottom: 10px;
}

.settings .cloud .description .actions {
  padding-top: 10px;
}

.settings .cloud .btn-start {
  padding: 0 40px;
  height: 38px;
  line-height: 38px;
}

.settings .cloud .label {
  text-align: right;
  padding-right: 10px;
}

.settings .cloud .controls {
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  color: #bdbdbd;
  letter-spacing: 0.3px;
}

.settings .main.cloud {
  width: 90%;
}

.settings .main.cloud .btn-default {
  width: 145px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.settings .main.cloud .row-fluid {
  padding-bottom: 10px;
}

.settings .main.cloud .label {
  vertical-align: top;
}

.settings .main.cloud .reset-password .row-fluid {
  margin: 0;
}

.settings .main.cloud .reset-password .entry {
  margin: 5px 0 10px 0;
}

.settings .main.cloud .reset-password input {
  width: 100%;
}

.settings .main.cloud .connected-printer {
  margin-top: 10px;
  display: inline-block;
  vertical-align: top;
}

.settings .main.cloud .name,
.settings .main.cloud .actions {
  display: inline-block;
  text-align: left;
}

.settings .main.cloud .footer {
  width: 90%;
  position: absolute;
  bottom: 60px;
}

/*create account*/

.settings .top-nav {
  margin-top: 20px;
  overflow: auto;
}

.settings .top-nav .back {
  float: left;
  margin-left: 20px;
}

.settings .top-nav .cancel {
  float: right;
  margin-right: 20px;
}

.settings .create-account .account-info {
  width: 240px;
  margin: 0 auto;
}

.settings .create-account h2 {
  margin-top: 58px;
  margin-bottom: 32px;
  font-size: 24px;
  color: #424242;
  font-weight: 500;
}

.settings .create-account .account-info input {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #bdbdbd;
  height: 35px;
}

.settings .create-account .form-group {
  margin-bottom: 20px;
}

.settings .create-account .actions {
  text-align: center;
}

.settings .create-account .btn-confirm {
  width: 100%;
  margin: 10px 0;
}

/*printer setting*/

.settings .form.printer {
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #424242;
  height: 360px;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 14px;
  letter-spacing: 0.3;
  color: #777;
}

.settings .printer .name {
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #4a4a4a;
}

.settings .printer .btn-default {
  width: 145px;
  height: 34px;
  border: 1px #e0e0e0 solid;
  line-height: 34px;
}

.settings .printer .printer-list {
  margin: 0 0 0 30px;
  width: 200px;
}

.settings .printer .fa {
  margin-right: 10px;
}

.settings .printer-list li {
  margin: 15px 0;
  cursor: pointer;
  font-weight: 500;
}

.settings .printer .printer-name {
  margin-top: 0;
}

.settings .printer .printer-name div {
  border-bottom: 1px #ddd solid;
  padding: 10px 0;
}

.settings .printer .set-password {
  margin-top: 26px;
}

.settings .printer .set-password .password {
  margin: 10px 0;
}

.settings .printer .set-password span {
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #9b9b9b;
}

.settings .printer .password td:nth-child(2) {
  padding-left: 20px;
}

.settings .printer .wifi {
  margin-top: 26px;
}

.settings .printer .wifi .join-network {
  margin: 10px 0;
}

.settings .printer .remove-printer {
  padding: 30px 0 0 101px;
}

.settings .printer .reset-password {
  overflow: auto;
}

.settings .printer .reset-password .row-fluid {
  margin-top: 20px;
}

.settings .printer .reset-password .entry {
  padding-top: 10px;
}

.settings .printer .reset-password input {
  width: 100%;
}

.settings .printer .reset-password button {
  float: right;
}

.settings .printer .remove-printer a {
  text-decoration: underline;
}

/*detect new wifi*/

.settings .wifi .btn.btn-confirm {
  margin-top: 30px;
}

.settings .wifi img.loading {
  margin: 15px 0;
}

/*select wifi*/

.settings .select-wifi .caption {
  margin-top: 50px;
}

.settings .select-wifi .footer-message a {
  text-decoration: underline;
}

.settings .select-wifi li {
  text-align: left;
  line-height: 40px;
}

.settings .select-wifi li .fa {
  width: 20px;
  padding-left: 10px;
}

/*set wifi password*/

.settings .wifi .wifi-symbol {
  margin-top: 0px;
}

/*set wifi printer*/

.settings .set-printer .flux-printer img {
  width: 125px;
}

.settings-gradient-overlay {
  background: linear-gradient(to bottom, rgba(247, 247, 247, 100%) 15%, rgba(247, 247, 247, 0%) 100%);
  height: 85px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
