.ja {
  &.beambox-studio {
    .tool-panels {
      .tool-block {
        .btn-h-group {
          .btn {
            font-size: 14px;
            padding: 0 12px;
          }
        }
      }
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(1) {
    .rc-menu {
      min-width: 250px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(2) {
    .rc-menu {
      min-width: 250px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(3) {
    .rc-menu {
      min-width: 360px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(4) {
    .rc-menu {
      min-width: 280px;
    }
  }
}
