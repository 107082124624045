@use "../../../abstracts/variables";

#drag-image {
  position: fixed;
  z-index: -1;
  width: variables.$sidePanelWidth;
  background-color: transparent;

  .layer-back {
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: #444;
    border: 1px solid #fff;
  }

  .layercolor {
    width: 24px;

    div {
      cursor: pointer;
      position: relative;
      border: 2px solid white;
      border-radius: 4px;
      background: black;
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
  }

  .layervis {
    text-align: center;
    margin: 0 5px;

    &.layerinvis {
      i {
        display: none;
      }
    }
  }

  .layer {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40px;
    color: white;
    background-color: #444;
    border: 1px solid #fff;
    font-weight: normal;

    .layer-row {
      display: flex;
      align-items: center;
      min-height: 30px;
    }

    .drag-sensor-area {
      width: 100%;
      height: 5px;
    }

    .layername {
      width: 150px;
      padding: 3px 0px;
      margin-left: 10px;
      text-align: center;
    }

    .layerlock {
      width: 14px;
      margin: 0 5px;
      cursor: pointer;

      img {
        display: none;
        height: 13px;
        filter: brightness(0%);
      }
    }

    &.layersel .layerlock {
      img {
        filter: brightness(0%) invert(100%);
      }
    }

    &.lock .layerlock {
      img {
        display: block;
      }
    }

    &.mark,
    &.mark:hover {
      color: white;
      border: 1px solid #666 !important;
      background-color: #666 !important;
      font-weight: normal;
    }
  }
}

#layerlist {
  width: 100%;
  .layercolor {
    width: 24px;
    div {
      cursor: pointer;
      position: relative;
      border: 2px solid white;
      border-radius: 4px;
      background: black;
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
  }
  .layer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40px;
    border: 1px solid #ffffff;
    border-width: 0 0 0 5px;
    &:hover {
      color: black;
      background-color: #dddddd;
      border-color: #dddddd;
    }
    .vis-icon {
      filter: brightness(0);
    }
    .layer-row {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 30px;
    }
    .drag-sensor-area {
      width: 100%;
      height: 5px;
    }
    .layername {
      width: 140px;
      padding: 3px 0px;
      margin-left: 5px;
      text-align: center;
      cursor: pointer;
    }
    &.layersel {
      color: white;
      background-color: #444444;
      border-color: #444444;
      font-weight: normal;
      &:hover:not(.current) {
        color: white;
        background-color: #444;
        border-color: #444444;
      }
      &.current {
        border-color: #0091ff;
      }
      .vis-icon {
        filter: brightness(0) invert(1);
      }
    }
    .layervis {
      display: flex;
      align-items: center;
      width: 24px;
      height: 25px;
      text-align: center;
      margin: 0 0 0 5px;
      cursor: pointer;
    }
    .layerlock {
      width: 24px;
      margin: 0 5px 0 0;
      cursor: pointer;
      img {
        display: none;
        filter: brightness(0%);
      }
    }
    &.layersel .layerlock {
      img {
        filter: brightness(0%) invert(100%);
      }
    }
    &.lock .layerlock {
      img {
        display: block;
      }
    }

    &.mark,
    &.mark:hover {
      color: white;
      border: 1px solid #666 !important;
      background-color: #666 !important;
      font-weight: normal;
    }
  }
  .drag-bar {
    width: 100%;
    height: 2px;
    background-color: #0091ff;
  }
}
