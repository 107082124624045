.rating-panel {
  width: 350px;
  padding: 20px 20px 20px 20px;
  background: #f8f8f8;
  border: 2px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);

  .pull-right {
    margin-right: 0px;
    margin-left: 10px;
  }

  .icon {
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      width: 100px;
    }
  }

  .title {
    height: 26px;
    line-height: 26px;
    color: #626262;
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
  }

  .description {
    margin-top: 5px;
    line-height: 22px;
    color: #626262;
    text-align: center;
    font-size: 16px;
  }

  .main-content {
    width: 100%;
    //height: 320px;
    margin: 15px 0px;
    overflow: scroll;
    //border: solid #CCC;
    //border-width: 1px 0px;
    color: #666666;

    &::-webkit-scrollbar {
      display: none;
    }

    .modal-checkbox {
      width: 100%;
      text-align: left;
      margin-top: 15px;
      padding-left: 8px;
      font-size: 14px;
      display: flex;
      line-height: 14px;

      input {
        margin: 1px 5px 0 0;
      }
    }

    .stars {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        padding: 0 5px;
      }

      img:hover {
        cursor: pointer;
      }
    }
  }

  .footer {
    height: 34px;
    display: flex;
    justify-content: center;

    .btn {
      margin-right: 0;
      height: 34px;
      line-height: 30px;
    }

    .primary {
      margin-right: 10px !important;
    }
  }
}

.thank-you {
  width: 250px;
  padding: 20px 20px 20px 20px;
  background: #f8f8f8;
  border: 2px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 128px;
    }
  }

  .text {
    margin-bottom: 5px;
    line-height: 22px;
    color: #626262;
    text-align: center;
    font-size: 16px;
  }

  .footer {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      margin-right: 0;
      height: 34px;
      line-height: 30px;
    }
  }
}
