.path-input {
  display: flex;
  align-items: center;

  .dialog-btn {
    cursor: pointer;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    border: 1px solid #bbbbbb;
    border-radius: 4px;

    &:hover {
      background-color: #eeeeee;
    }

    img {
      padding: 4px;
    }
  }
}
