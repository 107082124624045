.accordion {
  .accordion-body {
    display: block;
    height: 0;
    overflow: hidden;
    transform: scaleY(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .accordion-switcher {
    display: none;
  }

  .accordion-switcher:checked~.accordion-body {
    height: auto;
    transform: scaleY(1);
  }

  .header {
    color: #e0e0df;
    width: auto;
  }

  input {
    border: 2px solid #e0e0df;
    color: #888;
    font-size: 15px;
    margin: 0 0 0 10px;
    width: 140px;

    &.p66 {
      width: 100px;
    }

    &.p33 {
      margin-left: 0;
      width: 75px;
    }
  }

  .caption {
    &:hover {
      cursor: pointer;
      -webkit-filter: brightness(80%);
      filter: brightness(80%);
    }

    .value {
      color: #e0e0df;
    }
  }
}
