.es {
  &.beambox-studio {
    .tool-panels {
      .tool-block {
        width: 250px;

        .btn-h-group {
          .btn {
            padding: 0 12px;
          }
        }
      }

      .tool-panel {
        .controls {
          width: 250px;

          .text-center {
            width: 50px;
          }
        }
      }
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(1) {
    .rc-menu {
      min-width: 250px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(2) {
    .rc-menu {
      min-width: 250px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(3) {
    .rc-menu {
      min-width: 410px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(4) {
    .rc-menu {
      min-width: 330px;
    }
  }
}
