.dialog-container {
  position: absolute;

  .btn {
    margin-right: 5px;
  }

  .loading-background {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #f0f0f0;
  }
}
