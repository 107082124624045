.showable-password-input {
  display: flex;
  align-items: center;

  input {
    width: 100%;
    padding-right: 30px;
  }

  img {
    width: 30px;
    height: 30px;
    margin: 0 0 0 -40px;
    cursor: pointer;
  }
}
