@mixin absolute-center() {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin flexbox() {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin force-hide() {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
