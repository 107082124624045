.de {
  &.beambox-studio {
    .tool-panels {
      .tool-block {
        width: 300px;
      }

      .tool-panel {
        .controls {
          width: 300px;

          .text-center {
            width: 70px;
          }
        }
      }
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(1) {
    .rc-menu {
      min-width: 200px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(2) {
    .rc-menu {
      min-width: 290px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(3) {
    .rc-menu {
      min-width: 400px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(4) {
    .rc-menu {
      min-width: 350px;
    }
  }
}
