@use 'sass:meta';
@use 'beambox/top-bar';

.beambox-studio {
  @include meta.load-css('beambox/left-toolbar');
  @include meta.load-css('beambox/right-panel/right-panel');
  @include meta.load-css('beambox/svg-editor');
  @include meta.load-css('beambox/tool-panels');

  & {
    user-select: none;
    position: fixed;
  }

  svg:not(:root) {
    overflow: visible;
  }

  #svgcontent {
    *[data-tempgroup='true'] {
      rect,
      path,
      ellipse,
      circle,
      polygon,
      g,
      line,
      text {
        stroke: #0091ff !important;
      }
    }

    .layer {
      &[data-lock='true'] {
        pointer-events: none !important;
      }
    }
  }
}
