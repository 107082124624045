@media screen and (min-width: 601px) {
  #mobile-tab-bar {
    display: none;
  }
  .hidden-desktop {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .hidden-mobile {
    display: none !important;
  }

  #mobile-tab-bar {
    display: block;
    position: fixed;
    width: 100%;
    border-top: solid 1px #ccc;
    background: #ffffff;
    z-index: 998;
    overflow-x: scroll;
    bottom: 0;
  }

  #right-panel {
    display: none;
  }

  .adm-tab-bar-item[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }

  .beambox-studio #svg_editor {
    left: 0px;
    width: calc(100%);
    &.mac {
      width: calc(100%);
    }
  }

  .beambox-studio {
    .element-title {
      margin-left: 15px;
    }
  }

  .ant-modal-content .ui-control-unit-input-v2 {
    .unit {
      width: 50px;
      text-align: end;
      margin-left: -50px;
      display: inline-block;
    }
  }

  .beambox-studio #right-panel {
    overflow-x: hidden;

    #sidepanels {
      .right-panel-tabs {
        display: none;
      }

      #layerpanel {
        width: 100vw;
        height: 100%;
        border: none;
        .react-contextmenu-wrapper {
          height: 100%;
        }
      }
      #layerlist_container {
        left: 0;
        width: 100vw;
        overflow-y: scroll;
        height: 100%;
      }
    }
  }
  #laser-panel {
    .controls {
      overflow-y: auto;
    }
  }
  #beamboxToolPanel {
    z-index: 999;
  }
}
