@font-face {
  font-family: 'Harlow Solid Italic';
  src: local('Harlow Solid Italic'), local('Harlow Solid');
}

// latin
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  // src: local('Open Sans'), local('OpenSans'), url("../fonts/open-sans-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
