.left-toolbar {
  position: absolute;
  top: 40px;
  left: 0px;
  height: 100%;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-width: 0 1px 1px 0;

  .tool-btn {
    text-align: center;
    line-height: 24px;
    filter: brightness(0);
    margin: 13px 6px 13px 7px;
    height: 30px;
    width: 36px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.3;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    &:hover:not(.disabled) {
      opacity: 0.8;
    }
  }

  .preview {
    .preview-buttons {
      position: absolute;
      width: 100vw;
      left: 90px;
      top: 30px;
      z-index: 2;
      pointer-events: none;

      .preview-control-button {
        cursor: pointer;
        pointer-events: initial;
        float: left;
        border-radius: 20px;
        height: 32px;
        background: #999;
        margin-right: 15px;

        .text {
          padding: 0 20px;
          color: white;
          top: 0px;
          left: 31px;
          line-height: 32px;
          font-size: 9pt;
          text-align: center;
          float: left;

          &.with-img {
            padding-left: 4px;
            padding-right: 4px;
          }
        }

        img {
          float: left;
          margin: 8px 0px 0px 13px;
          height: 16px;
          filter: brightness(0) invert(100%);
        }

        .cross-wrapper {
          padding: 11px 0px 0px 3px;
          float: left;
          width: 20px;
          height: 10px;

          .bars {
            display: block;
            position: absolute;
            background-color: white;
            width: 1px;
            height: 10px;
          }

          .bar1 {
            transform: translateX(4px) rotate(45deg);
          }

          .bar2 {
            transform: translateX(4px) rotate(-45deg);
          }
        }

        &.active {
          background: #0099cc;
        }
      }
    }
  }
}
