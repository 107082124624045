@use "sass:color";
@use "../abstracts/mixins";

.ui-control-text-toggle {
  cursor: inherit;
  display: block;

  input {
    display: none;

    &+span::after {
      content: attr(data-text-off);
    }
  }

  input:checked {
    &+span::after {
      content: attr(data-text-on);
    }
  }
}

.ui-control-text-input,
.ui-control-unit-input {
  background: #fff;
  border: 0;
  color: #888;
  font-size: 1.2rem;
  height: 48px;
  padding: 2px 5px;
  width: 100%;
}

.ui-dialog-menu .ui-control-unit-input {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.ui-dialog-menu {
  $fontSize: 1.7rem;
  $primaryColor: #888;
  $backgroundColor: #e0e0df;

  list-style: none;
  margin: 0;
  padding: 0;

  .ui-dialog-menu-item {
    @include mixins.flexbox();
    height: 48px;
    line-height: 48px;
  }

  .dialog-opener {
    display: none;

    &:checked~.dialog-label {
      background: $primaryColor;
      color: $backgroundColor;
    }

    &:checked~.dialog-window {
      @include mixins.flexbox();
    }
  }

  .dialog-label {
    color: $primaryColor;
    cursor: pointer;
    font-size: $fontSize;
    margin: 0;
    min-width: 200px;
    overflow: hidden;
    padding: 0 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    border-radius: 1px;

    &:hover .btn,
    &:hover {
      background: $primaryColor;
      color: #e0e0df;
    }

    &:active .btn,
    &:active {
      background: color.adjust($primaryColor, $lightness: -20%);
      color: #e0e0df;
    }

    .btn {
      transition: initial;
    }
  }

  .dialog-window {
    cursor: pointer;
    display: none;
    align-self: center;
    z-index: 1;

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      color: #e0e0df;
      font-size: $fontSize;
    }

    .dialog-window-content {
      background: $primaryColor;
      padding: 8px;
      border-radius: 2px;

      li {
        padding: 0 15px;

        &:hover {
          background: #555;
        }
      }
    }

    .arrow {
      background: $primaryColor;
      transform: translateX(1px);
      align-self: center;
    }
  }
}

