.noun-project-panel-container {
  .noun-project-panel {
    background: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);
    width: 376px;

    .tabs {
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;

      .tab {
        width: 30%;
        margin: 0 10%;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.active {
          border: 2px solid #000000;
          border-width: 0 0 2px 0;
        }

        &:hover:not(.disabled) {
          opacity: 0.5;
        }
      }
    }

    .search-panel {
      padding: 15px 30px;
      display: flex;
      align-items: center;

      .search-icon {
        height: 24px;
        padding: 2px;
        margin-right: 10px;
      }

      #search-input {
        width: calc(100% - 68px);
        border: 0px;
        outline: none;
        background-color: #f8f8f8;
        font-size: 20px;

        &::placeholder {
          color: #aaaaaa;
        }
      }

      .cancel-button {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        cursor: pointer;

        img {
          width: 100%;
          padding: 3px;
          filter: brightness(0);
        }

        &:before,
        &:after {
          // content: '';
          position: absolute;
          height: 18px;
          width: 4px;
          border-radius: 1px;
          background-color: #ffffff;
        }

        &:before {
          transform: translate(12px, 5px) rotate(45deg); // translate((container.width - width / 2), (container.height - height / 2))
        }

        &:after {
          transform: translate(12px, 5px) rotate(-45deg);
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .content-container {
      height: 321px;
      border-top: 1px solid #dddddd;
      overflow-y: scroll;

      &.no-header {
        height: 383px;
      }

      .icons-list {
        padding: 0px 20px 0px 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .icon-cell {
          height: 70px;
          width: calc(25% - 10px);
          margin: 5px;
          border-radius: 10px;

          img {
            margin: 5px;
            width: calc(100% - 10px);
            height: 60px;
            object-fit: contain;
          }

          &.highlight {
            background-color: #cccccc;
          }
        }

        .loading-placeholder {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .no-icon-results {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 320px;
      color: #b3b3b3;
    }

    .icon-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 0px 20px;
      height: 320px;
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      background-color: #444444;
      border-top: 1px solid #dddddd;
      border-radius: 0 0 5px 5px;

      .term {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: #ffffff;
      }

      .info {
        height: 20px;
        margin: 0 5px 0 0;

        img {
          height: 20px;
          padding: 1px;
          filter: brightness(0) invert(1);
        }

        &.disabled {
          opacity: 0.5;
        }

        &:hover:not(.disabled) {
          opacity: 0.7;
        }
      }
    }

    .btn {
      height: 30px;
      line-height: 27px;

      &.hide {
        opacity: 0;
        cursor: default;
      }

      &.pull-right {
        margin: 0px 0px 0px 15px;
      }
    }
  }

  .modal-window {
    background-color: unset;
  }

  .info-window {
    position: absolute;
    min-width: 150px;
    padding: 7px 10px 7px 10px;
    border-radius: 5px;
    border: 1px solid #aaaaaa;
    font-size: 12px;
    background-color: #eeeeee;
  }
}
