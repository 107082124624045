#original-image {
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}

.photo-edit-panel {
  border-radius: 2px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 0px;
  background-color: #fff;

  .main-content {
    display: flex;
    padding: 20px;
    justify-content: center;

    .image-container {
      display: inline-block;
    }

    .scroll-bar-container {
      width: 140px;
      display: inline-block;
      padding-left: 20px;

      &.sharpen {
        width: 290px;
      }

      .sub-functions {
        margin-top: 10px;
        height: 55px;

        &.with-slider {
          height: 120px;
          width: 270px;

          .controls {
            padding-top: 10px;
            padding-bottom: 5px;

            input {
              width: 40px;
            }

            .slider {
              width: 120px;
            }
          }
        }

        .label {
          color: #888;
          height: 40px;
          line-height: 40px;
          width: 80px;
          font-size: 14px;
        }

        .slider-container {
          margin-right: 10px;

          input {
            width: 120px;
          }
        }

        .icon {
          float: right;
          opacity: 0.3;
          width: 34px;
          height: 34px;
          margin-top: 10px;
          margin-left: 7px;

          &:hover {
            opacity: 0.8;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .btn {
        margin-top: 10px;
        line-height: 30px;

        &.disabled {
          &:hover {
            border: 2px solid #c0c0c0;
            color: #b3b3b3;
          }
        }
      }
    }

    .title {
      color: #626262;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    .curve-panel {
      margin-left: 20px;

      .curve-control-container {
        width: 260px;
        height: 260px;
        overflow: hidden;
      }

      .curve-control-svg {
        border: 2px solid #b3b3b3;
        width: 260px;
        height: 260px;

        rect {
          cursor: crosshair;
        }

        path {
          cursor: crosshair;
        }
      }
    }
  }

  .footer {
    background: #fff;
    height: 59px;
    margin-bottom: 0px;
    border-top: 1px solid #ddd;

    .btn-h-group {
      display: block;
      height: 100%;
      padding: 12px;
      justify-content: flex-end;
    }

    .btn {
      margin-right: 10px;
      line-height: 30px;

      &:active {
        background-color: #cccccc;
      }

      &.pull-right {
        margin-right: 10px;

        &:first-child {
          margin-right: 0;
        }
      }

      &.pull-left {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

      &.disabled {
        pointer-events: none;
      }
    }
  }
}
