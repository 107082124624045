.maintain-move-panel {
  .st0 {
    fill: #555249;
  }

  .st1 {
    fill: #666360;
  }

  .st2 {
    fill: #807c79;
  }

  .st3 {
    fill: #999693;
  }

  .st4 {
    fill: #b3b0ab;
  }

  .st5 {
    fill: #fffcf9;
    pointer-events: none;
  }

  .st6 {
    fill: #444138;
  }

  .st7 {
    fill: #e6e3e0;
  }

  #svg-home-btn:active .st6,
  #svg-home-btn:active .st5 {
    transform: translateY(5px);
  }

  #svg-home-btn:active .st6 {
    fill: #333;
  }

  .dest-btn .st1:active,
  .move-btn:active,
  .xmin-btn:active,
  .xmax-btn:active,
  .ymin-btn:active,
  .ymax-btn:active {
    fill: #333;
    transform: translateY(5px);
  }
}
