$print-default-font-color: #4A4A4A;
$default-button-border-radius: 4px;
$size: 200px;
$max-z-index: 9999; //騙人-_- 最好是MAX啦
$primaryColor: #888;
$backgroundColor: #F0F0F0;
$sidePanelWidth: 242px;
$winSidePanelWidth: 258px;
$panelBorderColor: #E0E0E0;
$topBarHeight: 40px;
$s3: "https://beam-studio-web.s3.ap-northeast-1.amazonaws.com";
