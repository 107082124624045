.network-testing-panel {
  &.hide {
    display: none;
  }

  border-radius: 2px;
  $default-font-color: #4a4a4a;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2),
  0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  margin: auto;

  .main-content {
    width: 480px;
    padding: 20px;

    .title {
      font-size: 20px;
      color: #333;
      margin-bottom: 15px;
      width: 200px;
    }

    .info {
      font-size: 15px;
      height: 32px;
      line-height: 32px;
      letter-spacing: 0.3px;
      color: #555;

      .left-part {
        float: left;
      }

      .right-part {
        margin-left: 20px;
        float: left;
      }
    }

    input {
      width: 140px;
      border: 1px solid #ccc;
      border-radius: 2px;
      padding-left: 12px;
      height: 32px;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    border-color: #dddddd;
  }

  .footer {
    $default-font-color: #4a4a4a;
    height: 59px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0px;
    border-top: 1px solid #ddd;

    .btn {
      margin-left: 12px;
      margin-right: 0px;
      line-height: 30px;

      &:first-of-type {
        margin-right: 12px;
      }

      &.disabled {
        &:hover {
          border: 2px solid #c0c0c0;
          color: #b3b3b3;
        }
      }
    }
  }
}

.modal-alert {
  .hint-container {
    &.network-testing {
      text-align: left;
      margin-left: 15px;

      .hint {
        width: fit-content;
        font-size: 13px;
        height: 20px;
        line-height: 20px;
        color: #999999;
        cursor: pointer;

        &:hover {
          color: #333333;
        }
      }
    }
  }
}
