@use "../../abstracts/mixins";
@use "../../abstracts/variables";

.tool-panels {
  display: absolute;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: left 0.1s ease, top 0.1s ease, opacity 0.1s ease;

  &.unselectable {
    user-select: none;
    pointer-events: none;
  }

  .tool-panel {
    @include mixins.flexbox();
    font-size: 20px;
    transition: all 0.1s ease;
    z-index: 1;
    will-change: top, left, transform;

    .tool-panel-body {
      overflow: visible;
      position: relative;
      transform: scaleY(1);

      .offset-dist {
        input {
          margin: 0;
        }
      }

      .offset-dir,
      .offset-corner {
        select {
          line-height: 14px;
        }
      }

      &.collapsed {
        max-height: 0;
      }
    }

    .caption {
      margin: 0;
    }

    .accordion .caption .value {
      color: #999;
    }

    .controls {
      background: #ffffff;
      display: block;
      margin: 0;
      padding: 0 20px;
      width: 240px;
      border: 1px #ddd solid;

      .control {
        .text-center.header {
          color: #999;
        }

        font-size: 20px;
        margin: 0 0 10px 0;

        .input-fullsize {
          margin: 0;
          width: 100%;
        }

        .input-halfsize {
          display: inline-block;
          width: 45%;

          input {
            width: 80%;
          }
        }

        >div {
          margin-right: unset;
        }

        input[type="range"] {
          border: unset;
          cursor: pointer;
        }
      }
    }

    .ui.ui-control-unit-input-v2 {
      .unit {
        font-size: 10px;
        margin-left: -30px;
      }

      input[disabled] {
        background-color: #f2f2f2;
        cursor: not-allowed;
      }
    }

    .caption {
      @include mixins.flexbox();
      color: variables.$primaryColor;
      font-size: 12px;
      line-height: 30px;
      justify-content: space-between;
    }
  }

  .tool-block {
    font-size: 20px;
    transition: all 0.1s ease;
    z-index: 1;
    color: #999;
    background: #ffffff;
    display: block;
    margin: 0;
    padding: 0 20px;
    width: 240px;
    border: 1px #ddd solid;

    .btn-h-group {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;

      .btn {
        font-size: 1.3rem;
        height: 2.8rem;
        line-height: calc(2.8rem - 4px); // borders are 2px
        margin: 0 0.6rem 0 0;
        max-width: 13rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
