.dxf-dpi-selector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 460px;
  min-height: 100px;
  padding: 20px;
  border-radius: 2px;
  background-color: #f8f8f8;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);

  .caption {
    margin: 0 0 20px;
    color: #626262;
  }

  .message {
    margin: 0 0 20px;
  }
}
