@use '../../abstracts/mixins';
@use '../../abstracts/variables';

#svg_editor {
  color: #000;
  font-size: 10pt;

  * {
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .react-contextmenu--visible {
    z-index: 5;
  }
}
* {
  touch-action: manipulation;
}
