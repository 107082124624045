.en {
  &.beambox-studio {
    .tool-panels {
      .tool-block {
        width: 250px;
      }

      .tool-panel {
        .controls {
          width: 250px;

          .text-center {
            width: 55px;
          }
        }
      }
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(1) {
    .rc-menu {
      min-width: 170px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(2) {
    .rc-menu {
      min-width: 200px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(3) {
    .rc-menu {
      min-width: 250px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(4) {
    .rc-menu {
      min-width: 250px;
    }
  }
}
