@use "sass:meta";
@use "../../../abstracts/mixins";

#right-panel {
  font-size: 10pt;

  .right-panel-tabs {
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-width: 0px 0 1px 1px;
    display: flex;
    cursor: pointer;

    .tab {
      display: inline-flex;
      width: 32px;
      border: 1px solid #e0e0e0;
      border-width: 0 1px 0 0;
      transition: all 0.2s ease-in-out;

      &:last-of-type {
        border-width: 0;
      }

      .tab-icon {
        float: left;
        width: 24px;
        margin: 0 4px;
      }

      .tab-title {
        display: none;
        font-size: 12px;
        font-weight: bold;
        line-height: 38px;
        color: #333333;
        opacity: 0.8;
      }

      &.selected {
        width: calc(100% - 32px);
        background-color: #ffffff;

        .tab-title {
          display: block;
        }
      }

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  #sidepanels {
    #sidepanel_handle {
      @include mixins.force-hide();
    }

    @include meta.load-css("layer-panel");
    @include meta.load-css("laser-panel");
  }
}
