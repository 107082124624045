.layer-color-config-panel {
  width: 550px;
  //height: 70vh;
  padding: 20px 20px 20px 20px;
  background: #f8f8f8;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);

  .pull-right {
    margin-right: 0px;
    margin-left: 10px;
  }

  .title {
    height: 26px;
    line-height: 26px;
    color: #626262;
    text-align: left;
    font-size: 20px;
  }

  .main-content {
    width: 100%;
    height: calc(60vh - 60px);
    margin: 20px 0px;
    overflow: scroll;
    border: solid #ccc;
    border-width: 1px 0px;
    color: #666666;

    &::-webkit-scrollbar {
      display: none;
    }

    .config-item {
      height: 36px;
      line-height: 26px;
      padding: 5px 0px 5px 10px;
      font-size: 14px;
      display: flex;

      &:first-child {
        margin-top: 15px;
      }

      .add-config {
        line-height: 22px;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        .plus {
          font-size: 22px;
          font-weight: 900;
          padding: 0px 5px 0px 1px;
        }
      }

      .ui-control-unit-input-v2 {
        display: flex;

        input[type="text"] {
          height: 26px;
          font-size: 14px;
          text-align: right;
        }

        .unit {
          font-size: 10px;
        }

        &.power {
          width: 60px;

          input[type="text"] {
            width: 60px;
            padding: 4px 17px 4px 0px;
          }

          .unit {
            margin-left: -15px;
          }
        }

        &.speed {
          width: 75px;

          input[type="text"] {
            width: 75px;
            padding: 4px 32px 4px 0px;
          }

          .unit {
            margin-left: -32px;
          }
        }

        &.repeat {
          width: 40px;

          input[type="text"] {
            width: 40px;
            padding: 4px 17px 4px 0px;
          }

          .unit {
            margin-left: -15px;
          }
        }
      }

      .color-hex {
        width: 90px;
        padding-left: 10px;

        //font-family: "Courier New";
        input[type="text"] {
          font-size: 14px;
          padding: 4px 0px;
          width: 100%;
          height: 100%;
          text-align: center;
        }
      }

      .text {
        padding-left: 10px;
        padding-right: 10px;
      }

      .remove {
        font-size: 12px;
        font-weight: 900;
        line-height: 26px;
        padding-left: 25px;
        cursor: pointer;
        opacity: 0.5;
        color: #000000;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .footer {
    height: 34px;

    .btn {
      height: 34px;
      line-height: 30px;
    }
  }

  .add-config-panel {
    padding: 20px 20px 20px 20px;
    background: #f8f8f8;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05);
    width: 300px;

    .title {
      font-size: 15px;
      color: #626262;
      margin-bottom: 20px;
    }

    .input-column {
      height: 36px;
      padding-bottom: 10px;
      font-size: 14px;
      display: flex;
      color: #888888;

      .color-block {
        width: 14px;
        margin: 6px 0px 6px 0px;
        border: 1px solid #ddd;
        border-radius: 3px;
      }

      .name {
        line-height: 26px;
        width: 110px;
        padding-right: 30px;
        text-align: right;

        &.color {
          width: 96px;
        }
      }

      input {
        height: 26px;
        text-align: right;
        width: 110px;
        padding: 4px 36px 4px 0px;
      }

      .unit {
        margin-left: -32px;
        font-size: 10px;
      }
    }

    .footer {
      padding-top: 10px;
      border: solid #ccc;
      border-width: 1px 0px 0px 0px;
    }
  }
}

.config-color-block {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
