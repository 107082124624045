.zh-cn {
  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(1) {
    .rc-menu {
      min-width: 170px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(2) {
    .rc-menu {
      min-width: 190px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(3) {
    .rc-menu {
      min-width: 220px;
    }
  }

  div.rc-menu-container>ul>li.rc-menu__submenu:nth-of-type(4) {
    .rc-menu {
      min-width: 200px;
    }
  }
}
