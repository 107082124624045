@use "../abstracts/variables";

.more-config-panel {
  .config-list-column {
    width: 45%;
    .title {
      display: inline-flex;
      color: #888888;
      font-weight: 500;
    }
  }
  .config-list {
    margin-top: 10px;
    border: 2px solid #e0e0df;
    height: 160px;
    overflow-y: auto;

    .config-entry {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0df;
      color: variables.$primaryColor;
      cursor: pointer;
      line-height: 20px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .entry-name {
        height: 19px;
        margin-left: 10px;
        float: left;
      }
      .sub-text {
        height: 19px;
        margin-left: 0px;
        margin-right: 5px;
        float: right;
        font-style: italic;
        font-size: 10px;
        font-weight: 300;
      }
      &.no-border {
        border-bottom: 0px;
      }
    }
    .selected {
      background-color: #e0e0e0;
      color: #555;
    }
  }

  .config-name {
    margin: 20px 0 10px 0;
    height: 21px;
    line-height: 21px;
    font-size: 15px;
    font-weight: bold;
    color: #888888;
  }

  .controls {
    padding: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
    &.disable {
      opacity: 0.5;
    }
    .controls-column {
      width: 45%;
    }

    .control {
      color: #888;
      display: flex;
      font-size: 13px;
      height: 40px;
      align-items: center;
      justify-content: space-between;

      .label {
        font-size: 13px;
        text-transform: uppercase;
        color: #888;
        line-height: 1em;
        width: 100px;
        align-self: center;
      }
      .ui-control-unit-input-v2 {
        margin: 0px 0px 0px 5px;
        width: 90px;
        input {
          height: 28px;
          width: 100%;
          font-size: 13px;
          padding: 3px 0px 3px 5px;
        }
        .unit {
          margin-left: -40px;
        }
      }
      .value-text {
        width: 120px;
        margin: 5px 0 5px 2px;
        padding: 0 5px;

        &::after {
          content: attr(data-tail);
        }
      }
    }
  }

  .form {
    margin: 0 0 10px 0;
  }
}
